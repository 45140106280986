export function useMeta(meta) {
  const config = useRuntimeConfig()

  meta = {
    title: 'Horiz.io | N°1 en Calcul Rendement Locatif & Gestion Locative',
    description:
      "Calculez la rentabilité réelle d'un bien immobilier, trouvez l'investissement idéal à réaliser selon votre profil, et pilotez votre gestion locative.",
    image: 'Horizio-investissement-locatif.jpg',
    imageAlt: 'Horiz.io, N°1 en Calcul Rendement Locatif & Gestion Locative',
    url: config.public.hpBaseUrl,
    siteName: 'Horiz.io',
    ...meta,
  }

  useServerSeoMeta({
    description: meta.description,
    ogTitle: meta.title,
    ogDescription: meta.description,
    ogImage: `${config.public.hpBaseUrl}/imgs/${meta.image}`,
    ogUrl: meta.url,
    ogSiteName: meta.siteName,
    twitterCard: 'summary_large_image',
    twitterSite: '@horiz_io',
    twitterDescription: meta.description,
    twitterTitle: meta.title,
    twitterImage: `${config.public.hpBaseUrl}/imgs/${meta.image}`,
    twitterImageAlt: meta.imageAlt,
    appleItunesApp: meta.appleItunesApp,
  })
}
